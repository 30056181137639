:root {
  --space-selectedColor: #007bee;
  --space-selectedColorDark: #14b974;
  --space-selectedColorWarning: #ec5f67;
  --space-selectedColorWarningLow: #f2c94c;
  --space-selectedColorDisabled: #b8b8b8;
  --space-selectedWidht: 2;
  --space-offsetToolBar: 64px;
  --space-border-color0: #bbb;
  --space-border-color1: #c4c4c4;
  --space-border-color2: #d6d6d6;
  --space-gray: #d6d6d6;
  --space-gray-light: #f8f9fc;
  --space-border-color3: #d6d6d6;
  /*PRINT VARS*/
  --print-paper-size-w: 297mm;
  --print-paper-size-h: 210mm;
  --print-paper-size-m: 12mm;
  --print-paper-size-title: 6mm;
  --print-paper-size-page-size: A4;
  --print-paper-size-page-size-or: landscape;
  --space-readonly-scale: 1;
}

$minScreen: 1280px;

.psvg-baseRect-base svg:not(.psvg-svg-hiddenOverflow):not(.psvg-prod-banner),
.psvg-baseRect-base svg:not(:root):not(.psvg-svg-hiddenOverflow):not(.psvg-prod-banner) {
  overflow: visible !important;
}

.psvg-prod-banner,
.psvg-prod-banner svg,
.psvg-svg-hiddenOverflow,
.psvg-svg-hiddenOverflow svg {
  overflow: hidden !important;
}

#CDKcopyElement {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1000;
  pointer-events: none;
  display: none;
  img {
    object-fit: contain;
    object-position: 50% 50%;
  }
}

._spcs_fullScreen {
  --space-offsetToolBar: 0px;

  fuse-sidebar,
  toolbar {
    display: none;
  }

  #container-2 {
    padding-left: 0 !important;
  }
}

#app-spaces-planogram {
  user-select: none;
}

.noSelect {
  user-select: none !important;
}

/*GRID SIZES*/
#spcg_grid {
  --spcg-h-fullBar: 48px;
  --spcg-h-menuBar: 48px;
  --spcg-h-titleBar: 80px;
  --spcg-border-color1: #c4c4c4;
  --spcg-border-color2: #d6d6d6;
  --spcg-titlevar-bgcolor: #eeeff2;
  --spcg-content-bgcolor: #f8f9fc;
  --spcg-border-content: 16px;
  --spcg-left-panel: 200px;
  --spcg-rigth-panel: 220px;
  --spcg-def-padding: 12px;
  ._spcs_fullScreen & {
    // --spcg-h-fullBar: 0px;
    --spcg-h-titleBar: 0px;
    --spcg-border-content: 0px;
    --spcg-content-bgcolor: #f8f9fc;
  }

  #spcg_grid_loader_wrapp {
    background-color: var(--card-color);
    margin-top: var(--spcg-border-content);
    margin-left: var(--spcg-border-content);
    width: calc(100% - var(--spcg-border-content) * 2);
    max-width: 100%;
    padding-top: 200px;
    position: relative;
    overflow: hidden;
    mat-spinner {
      zoom: 0.5;
    }
  }

  #spcg_titleBar {
    width: 100%;
    height: var(--spcg-h-titleBar);
    ._spcs_fullScreen & {
      display: none;
    }
  }

  #spcg_fullMenuBar {
    width: 100%;
    height: var(--spcg-h-fullBar);
    // ._spcs_fullScreen & {
    //   display: none;
    // }
  }

  #spcg_borderContent {
    padding: var(--spcg-border-content);
    position: relative;
  }

  #spcg_bottom_row {
    overflow: hidden;
    width: 100%;
  }

  #spcg_leftPanel {
    float: left;
    height: 100%;
    width: var(--spcg-left-panel);
    &.closed {
      display: none !important;
    }
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
  }

  #spcg_rightPanel {
    float: right;
    height: 100%;
    width: var(--spcg-rigth-panel);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &.closed {
      display: none !important;
    }
  }

  //Tamaño del canvas (las barras de menu se calculan aparte en el componente)
  #spcg_bottom_row,
  #spcg_grid_loader_wrapp,
  .spcg_bottom_rowHight {
    height: calc(
      100vh / var(--ux_zoom_scale) - var(--spcg-h-fullBar) - var(--space-offsetToolBar) - var(--spcg-border-content) -
        var(--spcg-border-content) - var(--spcg-h-titleBar)
    ) !important;
  }
}

//Fix disabled grayScale
#psvg-canvas-area {
  ._disableEvents,
  ._disableEvents > :not(._activeEvents) {
    filter: none !important;
  }
}

#spcg_panel_title_left,
#spcg_panel_title_right {
  height: var(--spcg-h-menuBar);
  border-bottom: 1px solid var(--space-border-color3);
  padding: 0 var(--spcg-def-padding);
  position: relative;
  z-index: 2;
  h4 {
    padding: 0;
    margin: 0;
    opacity: 0.6;
    line-height: calc(var(--spcg-h-menuBar) + 2px);
  }

  button {
    position: absolute;
    top: 4px;
    bottom: 0;
    right: 0;
    margin-right: 0px;
    opacity: 0.6;
    mat-icon {
      transform: scaleX(-1);
      font-size: 18px;
    }
  }

  .closed {
    display: none;
  }
}

#spcg_panel_title_right {
  h4 {
    margin-left: 32px;
  }

  button {
    right: initial;
    margin-right: initial;
    left: 0;
    mat-icon {
      transform: scaleX(1);
    }
  }
}

#reopen_spcg_panel_title_left,
#reopen_spcg_panel_title_right {
  position: absolute;
  top: calc(var(--spcg-h-fullBar) + var(--spcg-h-menuBar) + var(--spcg-h-menuBar));
  left: 0;
  margin-top: 24px;
  margin-left: 24px;
  background-color: #f8f9fc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  mat-icon {
    opacity: 0.6;
    font-size: 16px;
  }

  ._spcs_fullScreen & {
    margin-top: 6px;
    margin-left: 6px;
  }

  &.hide {
    display: none;
  }

  mat-icon {
    opacity: 0.6;
    // transform: scaleX(-1);
  }
}

#reopen_spcg_panel_title_right {
  left: initial;
  right: 0;
  margin-left: initial;
  margin-right: 26px;
  ._spcs_fullScreen & {
    margin-right: 6px;
  }

  mat-icon {
    transform: scaleX(-1);
  }
}

app-spaces-planogram .contentOnTop,
app-floorplan-main-editor .contentOnTop {
  position: relative;
  z-index: 1;
}

/*GRID COSMETICA*/
#spcg_bottom_row {
  background-color: var(--card-color);
}

#spcg_titleBar {
  background-color: var(--spcg-titlevar-bgcolor);
  //   overflow: hidden;
  #spcg_mainTitle_wrap {
    margin-left: var(--spcg-border-content);
    margin-top: calc(2px + (var(--spcg-border-content) * 1.5));
    // width: calc(100% - 360px);
    float: left;
    .toolbar-text {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    h1 {
      margin: 0;
      padding: 0;
      margin-top: -5px;
      font-size: 200%;
      font-weight: 600;
      opacity: 1;
      height: 40px;
      overflow: hidden;
    }

    .spcButtonBack {
      vertical-align: middle;
      cursor: pointer;
    }
  }

  #btnComments {
    float: right;
    margin-top: calc(4px + var(--spcg-border-content) + 1px);
    height: 36px;
    width: 36px;
    margin-right: 6px;
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.15);
    .mat-button-wrapper {
      padding: 0;
    }
    mat-icon {
      font-size: 18px;
      padding-top: 3px;
    }
  }

  #mainSave,
  #mainSaveTmp1,
  #mainSaveTmp2,
  #mainPrintBtn {
    float: right;
    margin-right: var(--spcg-border-content);
    margin-top: calc(4px + var(--spcg-border-content) + 1px);
  }

  #mainSave {
    margin-left: 12px;
    &.saveDisabled___ {
      filter: grayscale(1);
      opacity: 0.75;
      pointer-events: none;
    }
  }

  #mainSaveTmp1,
  #mainSaveTmp2,
  #mainPrintBtn {
    transform: scale(0.75);
    box-shadow: none !important;
    background-color: var(--card-color);
    margin-left: 0;
    padding-left: 0;
    margin-right: -2px;
    mat-icon {
      color: #007bee;
      margin-top: -2px;
    }

    &.fabDisabled {
      opacity: 0.15;
      pointer-events: none;
      mat-icon {
        color: #666;
        body.theme-dark & {
          color: #b4b4b4;
        }
      }
    }
  }
}

#spcg_fullMenuBar {
  background-color: var(--card-color);
  border-bottom: 1px solid var(--spcg-border-color1);
}

#spcg_leftPanel {
  border-right: 1px solid var(--spcg-border-color2);
}

#spcg_rightPanel {
  border-left: 1px solid var(--spcg-border-color2);
}

#spcg_borderContent {
  background-color: var(--spcg-content-bgcolor);
}

#spcg_borderContentIn,
#spcg_grid_loader_wrapp {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  ._spcs_fullScreen & {
    border-radius: none;
    box-shadow: none;
  }
}

.spcg_side_content_panel {
  padding: var(--spcg-def-padding);
}

#spcg_top_gridfullMenuBar {
  width: 100%;
  height: var(--spcg-h-fullBar);
  overflow: hidden;
}

#spcg_top_tabs {
  height: var(--spcg-h-fullBar);
  .spcg_top_tab {
    height: calc(var(--spcg-h-fullBar) - 0px);
    width: auto;
    display: inline-block;

    padding: 0 18px;
    padding-left: 16px;

    @media (min-width: $minScreen) {
      padding: 0 28px;
      padding-left: 26px;
    }

    mat-icon {
      float: left;
      margin-top: calc((var(--spcg-h-fullBar) * 0.3) - 0px);
      padding: 0;
      padding-right: 28px;
      opacity: 0.6;
      font-size: 18px;
      @media (max-width: $minScreen) {
        padding-right: 20px;
      }
    }

    p {
      float: left;
      line-height: calc(var(--spcg-h-fullBar) - 0px);
      padding: 0;
      margin: 0;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      body.theme-dark & {
        color: rgba(255, 255, 255, 1);
      }
    }

    opacity: 0.6;
    cursor: pointer;

    &.active {
      opacity: 1;
      border-bottom: 2px solid #2f4050;
      body.theme-dark & {
        border-bottom: 2px solid var(--space-selectedColor);
      }
      mat-icon,
      p {
        color: rgba(0, 0, 0, 0.87);
        body.theme-dark & {
          opacity: 1;
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .tab-icon {
      margin-left: 6px;
      color: var(--main-greenColor) !important;
    }

    &:not(.validate) {
      .tab-icon {
        display: none;
      }
    }
  }
}

.spcg_panelContentBox {
  padding: var(--spcg-def-padding);
  padding-bottom: calc(var(--spcg-def-padding) * 1.15);
  padding-top: calc(var(--spcg-def-padding) * 1.15);
  border-bottom: 1px solid var(--space-border-color3);
  line-height: 1.25;
  &.spcg_panelContentBox-withForm {
    padding-bottom: calc(var(--spcg-def-padding) * 1.15 - 12px);
  }
  &.spcg_panelContentBox-no-padding {
    padding: 0 !important;
  }
}

.spcg_panelContentBox_titleWrapp {
  display: block;
  position: relative;
  width: 100%;
  mat-icon {
    position: absolute;
    top: 0px;
    right: -4px;
    font-size: 18px;
    color: var(--space-selectedColor);
    cursor: pointer;
  }
}

.app-spaces-planogram .bigSvgButton {
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

/*CANVAS*/
#psvg-canvas {
  position: relative;
  overflow: hidden;

  app-svg-canvas-floorplans & {
    height: 100% !important;
  }
}

.svg-canvas-isLoading #psvg-content {
  opacity: 0 !important;
  pointer-events: none;
}

.svg-canvas-softLoad:not(.svg-canvas-isLoading) #psvg-content {
  //   opacity: 0.5 !important;
  pointer-events: none;
}

#psvg-canvas-main-svg {
  background: var(--card-color);
  width: 100%;
  body.theme-dark & {
    background: #2b3443;
    background: #273040;
  }
}

#psvg-canvas-area.psvg-drag-canZoom {
  cursor: zoom-in;
}

#psvg-canvas-area.psvg-drag-canDrag {
  cursor: grab;
}

/*mientras se draguea, los hijos pierden eventos*/
.psvg-noevents,
#psvg-canvas-area.psvg-drag-canZoom use,
#psvg-canvas-area.psvg-drag-canDrag use,
#psvg-canvas-area.psvg-drag-canZoom #psvg-content,
#psvg-canvas-area.psvg-drag-canDrag #psvg-content,
#psvg-canvas-area.cdk-drag-dragging #psvg-content {
  pointer-events: none !important;
}
.svg-canvas-isInZoom #psvg-content {
  pointer-events: none !important;
}

#psvg-canvas-area.cdk-drag-dragging {
  cursor: grabbing;
}

/*Distintas opacidades según condiciones*/
#psvg-canvas.psvg-svgBase-inSelect:not(.psvg-canvas-canDrag__):not(.svg-canvas-canZoom__) image:not(.selectedImg),
#psvg-canvas.svg-canvas-outsideEltInDrag:not(.psvg-canvas-canDrag__):not(.svg-canvas-canZoom__) image:not(.selectedImg) {
  opacity: 0.55;
}

#app-spaces-planogram.tabDESIGN .psvg-baseRect-img {
  opacity: 0.6 !important;
}

#app-spaces-planogram.tabDESIGN .psvg-moduleSelected .psvg-baseRect-img,
#app-spaces-planogram.tabDESIGN .psvg-shelfSelected .psvg-baseRect-img {
  opacity: 0.3 !important;
}

#psvg-canvas .psvg-baseRect-img.withInfoBanner:not(.selectedImg) {
  opacity: 0.4 !important;
}

#psvg-canvas .psvg-baseRect-img.prdIsFiltered:not(.selectedImg),
#psvg-canvas .psvg-baseRect-img.prdIsFiltered {
  opacity: 0.15 !important;
}

#app-spaces-planogram.tabPOP #psvg-modules .psvg-baseRect-img {
  opacity: 0.55 !important;
}

/**/

#psvg-canvas.psvg-canvas-canDrag #psvg-float-menuItem,
#psvg-canvas.svg-canvas-canZoom #psvg-float-menuItem,
#psvg-canvas.psvg-svgBase-inDrag #psvg-float-menuItem {
  display: none;
}

#psvg-canvas.svg-canvas-softLoad #psvg-float-menuItem {
  opacity: 0;
}

.psvg-hidden {
  display: none;
}

#psvg-float-menuItem {
  top: -1000px;
  position: absolute;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.spcDynamicMenuItem {
  padding: 12px 18px;
  padding-top: 14px;
}

.spcDynamicMenuItemInput {
  height: 24px;
  width: 90px;
  margin-right: 6px;
  text-align: right;
  border: none;
  border-bottom: solid 1px #000;
  margin-bottom: 0;
  padding-bottom: 0;
}

.spcDynamicMenuItemAccept {
  font-size: 12px;
  margin-left: 2px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.spcDynamicMenuItemInputWrapp {
  margin-top: 2px;
}

#psvg-float-menuItem-circle {
  fill: var(--space-selectedColor);
  stroke: var(--card-color);
  stroke-width: 1px;
}

#psvg-float-menuItem-line {
  stroke: var(--space-selectedColor);
  stroke-width: var(--space-selectedWidht);
}

#canvasViewMenuBar,
#canvasIndicatorsMenuBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: var(--spcg-h-menuBar);
  border-bottom: 1px solid var(--space-border-color1);
  background-color: var(--card-color);
  vertical-align: middle;
  text-align: right;
  padding-left: var(--spcg-def-padding);
  padding-right: 0px;
  p {
    position: absolute;
    top: 2px;
    left: var(--spcg-def-padding);
    pointer-events: none;
  }
}
#canvasViewMenuBar {
  overflow: hidden;
}
#canvasIndicatorsMenuBar {
  top: var(--spcg-h-menuBar);
  text-align: left;
  line-height: var(--spcg-h-menuBar);
  .spcChip {
    display: inline-block;
    vertical-align: middle;
    background-color: #f2f2f2;
    body.theme-dark & {
      background-color: #454d5a;
    }
    border-radius: 32px;
    font-size: 12px;
    padding: 5px 13px;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 1.25;
    cursor: pointer;
    &.active {
      color: var(--card-color) !important;
      background-color: var(--space-selectedColor) !important;
    }
  }
  #canvasIndicatorsMenuBarScroll {
    height: var(--spcg-h-menuBar);
    max-width: calc(100% - 40px);
    overflow: hidden;
    overflow-x: auto;
    margin-left: calc(var(--spcg-def-padding) * -1);
    padding-left: var(--spcg-def-padding);
    &::-webkit-scrollbar {
      height: 7px;
    }
  }
  #canvasIndicatorsMenuBarWrapp {
    width: max-content;
    height: var(--spcg-h-menuBar);
    display: block;
    overflow: visible;
    padding-right: 20px;
  }
}

#canvasViewMenuBar,
#layersStatus {
  .psvg-control {
    background-color: var(--card-color) !important;
    box-shadow: none !important;
    border: none !important;
    margin-top: 6px !important;
    padding-top: 2px;
    vertical-align: middle !important;
    opacity: 0.8 !important;
    margin-left: -6px;
    mat-icon {
      font-size: 18px;
    }
  }

  .psvg-control mat-icon {
    color: rgba(0, 0, 0, 0.6) !important;
    body.theme-dark & {
      color: rgba(255, 255, 255, 0.6) !important;
    }
    &:hover {
      opacity: 0.6 !important;
    }
  }

  .psvg-control._active mat-icon {
    opacity: 1 !important;
    color: rgba(0, 123, 255, 1) !important;
  }

  #psvg-control-reset {
    margin-left: 18px !important;
  }

  #psvg-control-redo {
    margin-right: 18px !important;
  }

  #psvg-control-undos.fabDisabled,
  #psvg-control-redo.fabDisabled {
    opacity: 0.2 !important;
    pointer-events: none;
  }
}

.listProdIndicIsListed._warning {
  color: var(--space-selectedColorWarning) !important;
}
.listProdIndicIsListed._warningLow {
  color: var(--space-selectedColorWarningLow) !important;
}
.listProdIndicIsListed._ok {
  color: var(--space-selectedColor) !important;
}

//Capas pero en el menúBar de canvas
#canvasViewMenuBar #canvasViewMenuBarLayers {
  position: absolute;
  top: 1px;
  left: 120px;
  width: 200px;
  #layersStatus {
    text-align: left !important;
    .psvg-control {
      width: 40px !important;
    }
  }
  @media (max-width: $minScreen) {
    left: 4px;
  }
}

app-spaces-planogram .cdk-drag-dragging,
app-floorplan-main-editor .cdk-drag-dragging {
  cursor: move;
}

.psvg-baseRect-selected {
  fill: none;
  pointer-events: none;
  stroke: var(--space-selectedColor);
  &.psvg-baseRect-selected-warning {
    fill: var(--space-selectedColorWarning);
  }
}

.psvg-baseRect-over {
  fill: rgba(30, 216, 141, 0.075);
  pointer-events: none;
  stroke: var(--space-selectedColor);
  &.psvg-baseRect-over-warning {
    fill: rgba(236, 95, 102, 0.075);
    stroke: var(--space-selectedColorWarning);
  }
  &.psvg-baseRect-over-warning-low {
    fill: rgba(236, 95, 102, 0);
    stroke: var(--space-selectedColorWarningLow);
  }
}

.psvg-baseRect-shelf-guide {
  fill: transparent;
  pointer-events: none;
  stroke: var(--space-selectedColor);
  &.psvg-baseRect-shelf-guide-warning {
    stroke: var(--space-selectedColorWarning);
  }
}

.psvg-baseRect-normalshelf-guide {
  fill: var(--space-selectedColorDark);
  pointer-events: none;
  &.psvg-baseRect-normalshelf-guide-warning {
    fill: var(--space-selectedColorWarning);
  }
}

.psvg-baseRect-module-guide {
  fill: var(--space-selectedColor);
  pointer-events: none;
  &.psvg-baseRect-module-guide-warning {
    fill: var(--space-selectedColorWarning);
  }
}

.pitShelfNormalView {
  fill: var(--space-gray);
  opacity: 0.3;
}

.psvg-baseRect-img,
.psvg-baseRect-floorPlan-img,
.psvg-baseRect-floorPlan-module,
.psvg-baseRect-floorPlan-module-child,
._disableEvents {
  pointer-events: none;
}

._disabledSpcBtn {
  pointer-events: none;
  opacity: 0.3;
}

._disabledSpcBtnImp {
  pointer-events: none !important;
  opacity: 0.3 !important;
}

//Sobrescribe el status de _disableEvents para analisis/producto (que muestre el tooltip)
.tabANALYSIS .psvg-baseRect-base {
  pointer-events: initial !important;
}

.flipY {
  transform: scaleY(-1) !important;
}

/*Prod Info Banner*/
.psvg-prod-banner-selected {
  pointer-events: none !important;
}
.psvg-prod-banner-info-shadow {
  opacity: 0.35;
  transform: translate(1px, 1px);
}
.psvg-prod-banner-text {
  font-size: 11px;
  font-weight: 600;
  transform: translateY(2px);
  &.isBig {
    font-size: 15px;
  }
}

.psvg-svg-quad-info-text,
.psvg-svg-fp-quad-info-text,
.psvg-svg-quad-no-image-text {
  font-weight: normal;
  @media screen {
    letter-spacing: -0.25px;
  }
}
.psvg-svg-fp-quad-info-text {
  font-weight: 600;
}
.psvg-svg-quad-info-text {
  @media screen {
    font-size: 12px;
  }
}
.psvg-svg-quad-no-image-text-name {
  font-weight: 600 !important;
  @media screen {
    letter-spacing: -0.25px;
  }
}

#printLoaderBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--card-color);
  opacity: 0.7;
  pointer-events: none;
  mat-spinner {
    zoom: 0.35;
  }
  @media print {
    display: none;
  }
}

/*FORMS*/
app-spaces-planogram,
app-templates-etitor,
app-floorplan-main-editor {
  .formFieldIcon {
    float: left !important;
    padding-top: 22px !important;
    font-size: 18px !important;
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
  .formFieldWithIcon {
    width: calc(100% - 24px) !important;
  }
  .spcg_panelContentBoxFormField {
    width: calc(100% - 12px) !important;
    .mat-form-field-label-wrapper {
      left: 0 !important;
    }
  }
  .resalt,
  .resalt_normal {
    font-weight: bold;
    padding-bottom: 4px;
    display: inline-block;
  }
  .resalt {
    color: var(--main-greenColor);
  }
}

/*COLORES*/
app-spaces-planogram .colorBox,
app-floorplan-main-editor .colorBox {
  width: 26px;
  height: 26px;
  border: 2px solid transparent;
  display: inline-block;
  margin-right: 6px;
  border-radius: 100%;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--space-selectedColor);
  }
  &[data-selected='true'] {
    border: 3px solid var(--space-selectedColor);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
  }
}

.iconRed {
  color: var(--space-selectedColorWarning) !important;
}
.colGreen {
  color: var(--main-greenColor);
}
.pointer {
  cursor: pointer;
}
.noColor {
  color: unset !important;
}

/*LISTADO*/
.spaceCellProd,
.spaceCellPop,
.spaceCellMod,
.spaceCellBlock {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--space-border-color3);
  margin-bottom: 0px;
  img {
    height: 60px;
  }
  p {
    margin-top: 8px;
    margin-bottom: 2px;
  }
  .cdk-drag {
    cursor: grab;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .dragElement {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0 !important;
  }
}
.spaceCellProd .dragElement,
.spaceCellPop .dragElement {
  width: 100%;
  height: calc(100% - 28px);
}

body.theme-dark .spaceCellMod img {
  opacity: 0.8;
}

//PRD Tooltips
.prodTtip {
  transform: translate(90px, -24px);
  pointer-events: none;
  font-size: 12px;
  text-align-last: left;
  /**/
  background-color: #fff !important;
  border: solid 1px var(--space-border-color3) !important;
  /**/
  &.tooltip-right:after {
    border-color: transparent var(--card-color) transparent transparent !important;
  }
  &.tooltip-top:after {
    border-color: var(--card-color) transparent transparent !important;
  }
  /**/
  &,
  div,
  .cell {
    color: #000 !important;
  }
}

.svgBaseInMove .prodTtip {
  display: none !important;
}

//indicadores banner tooltips
.indBannerTtip {
  pointer-events: none;
  font-size: 12px;
}
.svgBaseInMove .indBannerTtip {
  display: none !important;
}

#spaceCellProd_load_wrapp,
#spaceCellPop_load_wrapp {
  height: 60px;
  padding-bottom: 10px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.75;
  mat-spinner {
    zoom: 0.35;
  }
}

.dragModImg,
.dragBlockImg {
  mix-blend-mode: multiply !important;
  background-blend-mode: multiply !important;
}

//Listado Scrolleable de productos
#lookUpProdListWrapp,
#lookUpPopListWrapp {
  height: 48px;
  background: var(--card-color);
  z-index: 2;
  position: relative;
  .buttonFilters,
  .buttonSearch {
    position: absolute;
    top: 8px;
    right: 0;
    padding: 0;
    margin: 0;
    width: 32px;
    overflow: hidden;
    background-color: var(--card-color) !important;
    opacity: 0.4;

    cursor: pointer;
    body.theme-dark & {
      background: var(--card-color);
    }
    &._active {
      opacity: 1;
      color: var(--space-selectedColor);
    }
    #psvg-control-print._active {
      pointer-events: none;
    }
  }
  body.theme-dark & {
    background: var(--card-color);
  }
}

#lookUpProdList,
#lookUpPopList {
  border: solid 1px transparent;
  border-bottom: solid 1px var(--space-border-color1);
  width: calc(100% - 16px);
  width: -webkit-fill-available;
  padding: 4px;
  margin: 8px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 13px;
  opacity: 0.6;
  body.theme-dark & {
    color: #fff;
    background: var(--card-color);

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.prodListWrappScroll,
.popListWrappScroll {
  margin-top: calc((49px + 48px) * -1);
  padding-top: calc(48px + 48px);
  .prodListScroll,
  .popListScroll {
    // background-color: #ddd;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 6px;
    padding-right: 6px;
    pointer-events: visible;
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
}
.spaceCellProd-offsetTop,
.spaceCellPop-offsetTop {
  margin-top: 0px !important;
}
.spaceCellProd,
.spaceCellPop {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  .spcDragItem {
    opacity: 0.2;
  }
  &:hover {
    .name {
      color: var(--space-selectedColor);
    }
    .spcDragItem {
      opacity: 0.5;
    }
  }
}
.spaceCellProdInfo,
.spaceCellPopInfo {
  .spaceCellProdColLeft,
  .spaceCellPopColLeft {
    width: 46px;
    display: inline-block;
    vertical-align: middle;
    margin-left: -4px;
  }
  .spaceCellProdColRight,
  .spaceCellPopColRight {
    padding-left: 4px;
    width: calc(100% - (46px + 4px));
    display: inline-block;
    vertical-align: middle;
    .name {
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 13px;
      line-height: 1.15;
      font-weight: 500;
    }
  }
  .spcDragItem {
    display: inline-block;
    vertical-align: middle;
    width: calc(46px - 38px);
    height: auto;
  }
  img.imgProd,
  img.imgPop {
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    object-position: 50% 50%;
    height: 48px !important;
    width: 36px !important;
    padding-left: 6px;
  }
  .stack1,
  .stack3 {
    font-size: 11px;
    opacity: 0.7;

    body.theme-dark & {
      opacity: 0.85;
    }

    text-align: left;
    position: relative;
    .inf {
      padding-right: 4px;
      font-weight: 500;
    }
    .stack3-right {
      position: absolute;
      top: -1px;
      right: -8px;
      z-index: 1;
      width: 80px;
      height: 20px;
      text-align: right;
      mat-icon {
        font-size: 18px;
        margin-left: 0;
        margin-right: 0;
        vertical-align: middle;
        color: #666;
        body.theme-dark & {
          color: #b4b4b4;
        }
        &._active {
          color: var(--space-selectedColor);
        }
      }
    }
  }
  .stack2 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.btn_wSlide {
  span_OLD {
    float: right;
    vertical-align: middle;
    transform: scale(0.8);
    margin-left: 10px;
  }
  span {
    float: right;
    vertical-align: middle;
    transform: scale(0.9);
    margin-left: -2px;
    padding-left: 10px;
  }
  ::ng-deep .mat-ripple {
    display: none;
  }
}

.btn_wSlide_blocked {
  position: relative !important;
  padding-left: 32px !important;
  &:before {
    content: 'lock';
    display: block;
    font-family: 'material-outline-icons';
    position: absolute;
    font-size: 14px;
    top: 1;
    left: 12px;
  }
}

.resetFiltersMenuItem {
  color: var(--space-selectedColor) !important;
}
.toolTipPrdArea.inScroll {
  pointer-events: none !important;
}

._hideOnSamllScreen {
  @media (max-width: $minScreen) {
    display: none;
  }
}

/*VER CAPAS / TOP RIGHT*/
#layersStatus {
  display: block;
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  width: 100%;
  height: var(--spcg-h-menuBar);
  padding-top: 1px;
  line-height: var(--spcg-h-menuBar);
  padding-right: 8px;
  .psvg-control {
    position: relative;
    display: inline-block;
    top: 0;
    padding-top: 0;
    margin-top: 0 !important;
    width: 30px;
    &:hover {
      opacity: 0.8 !important;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.3 !important;
    }
  }
}

//Panel Info Productos
#spc-panel-prod-info,
#spc-panel-shelfs-info,
#spc-panel-audit,
#app-panel-constraints,
#spc-panel-analysis,
#spc-panel-generic-TMPL-panel {
  h4 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    display: block;
    margin-bottom: 14px;
    pointer-events: none;
    color: var(--space-selectedColor);
    span {
      display: inline-block;
      width: 80%;
      line-height: 1;
      vertical-align: middle;
    }
    img {
      height: 36px;
      max-width: 36px;
      vertical-align: middle;
      padding-right: 8px;
      object-fit: contain;
      object-position: 50% 50%;
    }
    &.fullWidth {
      &,
      span {
        width: 100%;
      }
    }
    &.noColor {
      color: unset !important;
    }
  }
  .infoSubtitle {
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 24px;
    padding: 0;
    font-size: 13px;
    opacity: 0.75;
    pointer-events: none;
    mat-icon {
      font-size: 16px;
      vertical-align: top;
    }
  }
  .subTit {
    padding-bottom: 6px;
    margin-bottom: 0;
    font-size: 13px;
    b {
      float: right;
      color: var(--space-selectedColor);
    }
  }
}

.SpcPaneDataListCols,
.SpcPaneDataListColsPrdTTip {
  .rowSplit {
    .cell {
      width: 50%;
    }
  }
  .row {
    margin-bottom: 1px;
  }
  .cell {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
  }
  .label {
    font-weight: bold;
    padding-right: 4px;
  }
  .topSep {
    margin-top: 8px;
  }
  .topSep2 {
    margin-top: 12px;
  }
  .topSep3 {
    margin-top: 14px;
  }
}

$ttpwidth: 320px;
.prodTtip {
  width: $ttpwidth !important;
  max-width: $ttpwidth !important;
}
.SpcPaneDataListColsPrdTTip {
  width: $ttpwidth !important;
  .SpcPaneDataListColsPrdTTipWrapp {
    padding: 10px;
    width: calc(100% - 20px);
    display: block;
    clear: both;
  }
  .cell {
    font-size: 13px;
    text-align: left;
  }
}

.SpcPaneDataListColsPrdTTip mat-icon {
  font-size: 18px;
  font-weight: normal !important;
  vertical-align: text-top;
  padding-right: 6px;
  &._active {
    color: var(--space-selectedColor);
  }
}

.panelAlert,
.buttonAlert {
  width: 100%;
  border: 1.5px solid var(--space-selectedColor);
  color: var(--space-selectedColor);
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  font-weight: bold;
  user-select: none;
  &.panelAlert-warn {
    border-color: var(--space-selectedColorWarning);
    color: var(--space-selectedColorWarning);
  }
  &.panelAlert-normal {
    border-color: var(--space-selectedColor);
    color: var(--space-selectedColor);
  }
  &.panelAlert-warninglow {
    border-color: var(--space-selectedColorWarningLow);
    color: var(--space-selectedColorWarningLow);
  }
  &.panelAlert-disabled {
    border-color: var(--space-selectedColorDisabled);
    color: var(--space-selectedColorDisabled);
  }
}
.buttonAlert {
  cursor: pointer;
}

.buttonBlockSpaces {
  display: flex;
  justify-content: center;
  align-items: center;
}

.barChart {
  width: 100%;
  background-color: #eee;
  height: 18px;
  text-align-last: left;
  overflow: hidden;
  .barChartVal {
    height: 18px;
    width: 0%;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    animation: animWidth 0.5s;
  }
  .barChartVal-col1 {
    background-color: var(--space-selectedColor);
  }
  .barChartVal-col2 {
    background-color: var(--space-selectedColorWarningLow);
  }
  .barChartVal-col3 {
    background-color: var(--space-selectedColorWarning);
  }
}

.advancedFiltersWrapp {
  text-align: center;
  width: 100%;
  .content-form {
    margin-top: 12px;
  }
  .advFilterFootBtns {
    text-align: center;
    display: block !important;
  }
  .btnWrapp {
    zoom: 0.85;
    text-align: center;
    width: 50%;
    margin-top: 16px;
    margin-bottom: 8px;
    display: inline-block !important;
    button {
      width: 95%;
    }
  }
  .applyFiltersDirect {
    .btnWrapp {
      width: 100%;
    }
  }
}

// ToolTip de indicadores
.indTooltip {
  pointer-events: none;
}
.indReferences {
  .indRampColorLabels {
    width: 100%;
    height: 20px;
    position: relative;
    font-size: 11px;
    padding-top: 4px;
    font-weight: lighter;
    .labelLeft {
      position: absolute;
      top: 0;
      left: 0;
    }
    .labelRight {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }
  }
  .indRampColor {
    width: 100%;
    min-width: 100px;
    height: 24px;
    margin-bottom: 8px;
    margin-top: 4px;
  }
  .indReferencesList {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 6px;
    .indReferencesListCell {
      text-align-last: left;
      font-size: 11px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: lighter;
    }
    .indRefListColor {
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      margin-right: 4px;
      margin-left: 1px;
    }
  }
}
#closeChipsIndicators {
  position: absolute;
  top: 15px;
  right: 6px;
  font-size: 20px;
  cursor: pointer;
  color: var(--space-selectedColor);
}

#generalTabWrapp {
  height: -webkit-fill-available;
  overflow-y: auto;
  pointer-events: auto;
}

/*ANIMS*/
.pulsingA {
  animation: anim_pulseA 0.35s;
}
.pulsingB {
  animation: anim_pulseB 0.35s;
}
.pulsingStart {
  animation: anim_pulseStart 0.45s;
}

@keyframes anim_pulseStart {
  0% {
    opacity: 0;
    color: #fff;
  }
}

@keyframes anim_pulseA {
  50% {
    opacity: 0;
    transform: translate(0%, -30%);
  }
  51% {
    opacity: 0;
    transform: translate(0%, 30%);
  }
}
@keyframes anim_pulseB {
  50% {
    opacity: 0;
    transform: translate(0%, -30%);
  }
  51% {
    opacity: 0;
    transform: translate(0%, 30%);
  }
}

@keyframes animWidth {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0%, 0);
  }
}

/*PRINT PLANOGRMS*/
#app-spaces-planogram {
  @media print {
    display: none;
  }
}

@page {
  background-color: #fff;
  size: var(--print-paper-size-page-size) var(--print-paper-size-page-size-or);
  size: A4 landscape;
  margin: calc(var(--print-paper-size-m) * 0.5);
}

._scrollTopPageContent {
  //FIX DES-2002
  @media print {
    overflow: visible !important;
  }
}

#planogram-print {
  &:not(.isDev) {
    visibility: hidden;
    pointer-events: none !important;
  }
  @media print {
    overflow: visible !important; //FIX DES-2002
    background-color: #fff;
    visibility: visible !important;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  #psvg-canvas {
    height: 100vh !important;
  }
  #canvasViewMenuBar,
  #canvasIndicatorsMenuBar {
    display: none !important;
  }
  .psvg-svg-quad-info,
  .psvg-svg-quad-no-image {
    @media print {
      stroke-width: 0.1mm;
    }
  }
  .planogram-print-paper-title {
    position: absolute;
    z-index: 1;
    top: calc(var(--print-paper-size-m) * 0.9);
    left: var(--print-paper-size-m);
    width: calc(100% - (var(--print-paper-size-m) * 2));
    height: var(--print-paper-size-title);
    line-height: 1;
    font-size: calc(var(--print-paper-size-title) * 0.6);
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
  }
  #psvg-canvas-main-svg {
    width: calc(var(--print-paper-size-w) - (var(--print-paper-size-m) * 2));
    height: calc(var(--print-paper-size-h) - (var(--print-paper-size-title) + (var(--print-paper-size-m) * 2)));
    margin: 0;
    margin-top: calc(var(--print-paper-size-title) + var(--print-paper-size-m) * 1);
    margin-left: calc(var(--print-paper-size-m) * 1);
  }
  #psvg-stage {
    rect {
      fill: transparent;
    }
  }
  .planogram-print-paper {
    position: relative;
    background-color: #fff;
    width: var(--print-paper-size-w);
    height: var(--print-paper-size-h);
    text-align: center;
    clear: both;
    margin: 0;
    padding: 0;
    /**/
    border: solid 2px #000;
    @media print {
      width: 100vw;
      height: 100vh;
      display: flex;
      border: none !important;
      page-break-after: always !important;
      page-break-before: always !important;
    }
  }

  .planogram-print-paper-report {
    @media print {
      width: 100%;
      height: initial !important;
    }
  }

  .planogram-print-paper-sep {
    height: 2px;
    margin-top: 2px;
    width: 100%;
    @media print {
      display: none;
    }
  }
}

//ANALYSIS
.menuAnalysis {
  margin-top: 16px;
  button {
    display: block;
    text-transform: none !important;
    &:hover,
    &:hover mat-icon {
      color: var(--space-selectedColor);
    }
    &.active,
    &.active mat-icon {
      color: var(--space-selectedColor);
    }
  }
  mat-icon {
    font-size: 20px !important;
  }

  ::ng-deep .mat-button-ripple {
    display: none !important;
  }
}

#spc-panel-analysis {
  max-width: 100%;
  overflow: hidden;
}

.analysis-subOptionsHeatMap {
  width: 100%;
  user-select: none;
  form {
    width: 100%;
  }
  .formField {
    width: 100%;
  }
}

.dividerAnalysisPanel {
  margin-left: calc(var(--spcg-border-content) * -1) !important;
  margin-right: calc(var(--spcg-border-content) * -1) !important;
  margin-top: var(--spcg-border-content) !important;
  margin-bottom: var(--spcg-border-content) !important;
}

#spc-panel-time-line {
  --openH: 130px;

  position: absolute;
  top: calc(var(--spcg-h-fullBar) * 2);
  height: var(--spcg-h-menuBar);
  left: 0;
  width: 100%;
  z-index: 1;
  /**/
  border-bottom: 1px solid var(--space-border-color1);
  background-color: var(--card-color);
  vertical-align: middle;
  text-align: left;
  overflow-y: hidden;

  #timeLinetitleWrapp {
    text-align: left;
    padding: 0 var(--spcg-def-padding);
    line-height: var(--spcg-h-menuBar);
  }
  #timeLineLoaderBox {
    pointer-events: none;
    display: inline-block;
    left: 6px;
    top: 5px;
    position: relative;
  }
  #timeLineOptionsBox {
    width: calc(100% - 200px);
    position: absolute;
    top: 0;
    right: var(--spcg-def-padding);
    text-align-last: right;
    text-align: right;
    vertical-align: middle;
    height: var(--spcg-h-menuBar);
    line-height: var(--spcg-h-menuBar);

    .mat-icon {
      cursor: pointer !important;
    }
  }
  .timeLineDate {
    display: inline-block;
    margin-right: 16px;
    vertical-align: super;
    .dateTL {
      color: var(--space-selectedColor);
    }
  }

  .opnClose {
    margin-left: 16px;
    color: var(--space-selectedColor);
  }

  &.time-line-open {
    height: calc(var(--spcg-h-menuBar) + var(--openH)) !important;
    border-bottom: 1px solid var(--space-border-color0);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  }

  #timeLineBoxSlideWrapp {
    margin: 6px var(--spcg-def-padding);
    margin-top: 0;
    height: calc(var(--openH) - (12px));
    width: auto;
    overflow: hidden;
  }
}

#atrrTagSelectedList {
  display: block;
  margin-top: 14px;
  margin-bottom: 14px;
  cursor: pointer;
  .selAttrColor {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    overflow: hidden;
    border: 2px solid transparent;
    border-radius: 100%;
    margin-right: 4px;
    &:hover {
      border: 2px solid var(--space-selectedColor);
    }
    &[data-selected='true'] {
      border: 3px solid var(--space-selectedColor);
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
    }
  }
}

/*AUDIT*/
#spc-panel-audit,
#app-panel-constraints {
  .auditListCell {
    margin-bottom: 24px;
  }
  .auditBtn,
  .constraintsBtn {
    width: 100%;
    margin-top: 16px !important;
    margin-bottom: 8px !important;

    border: 2px solid var(--space-selectedColor);
    color: var(--space-selectedColor);
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    border-color: var(--space-selectedColor);
    color: var(--space-selectedColor);

    .mat-icon {
      vertical-align: text-top;
      font-size: 16px !important;
      color: var(--space-selectedColor);
    }
    .mat-button-wrapper {
      font-size: 12px !important;
      position: relative;
      left: -8px !important;
      top: -1px;
    }
  }
}

#auditViewImgWrapp {
  width: 100%;
  margin-top: 0px;
  background-color: #f8f9fc;
}
#auditViewImgWrapp,
#auditViewImgWrapp #imgView {
  height: calc(100vh - 180px);
}
#auditViewImgWrapp #imgView {
  max-width: initial;
  width: 100%;
}
.auditViewClose {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
  user-select: none;
}
.auditViewHide {
  position: absolute;
  top: 16px;
  right: 46px;
  cursor: pointer;
  z-index: 100;
  user-select: none;
}
.auditHideModal .cdk-overlay-backdrop {
  pointer-events: none !important;
  opacity: 0;
}
.auditHideModal mat-dialog-container {
  height: 70px !important;
  overflow: hidden !important;
  position: relative;
  #auditViewImgWrapp {
    pointer-events: none !important;
    opacity: 0 !important;
  }
}

/*COMMENTS*/
.coomentClose {
  position: relative;
  cursor: pointer;
  z-index: 1;
  user-select: none;
  float: right;
}
#spcCommentListWrapp {
  padding: 12px 0;
  margin-bottom: 32px;
}
#spcCommentWriteWrapp {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--space-border-color0);
}
#spcCommentListLoad {
  padding-top: 16px !important;
  padding-bottom: 16px;
}
#spcCommentList {
  .spcCommentListCeld {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--space-border-color0);
  }
  .commentUser,
  .commentDate {
    display: inline-block;
    width: 50%;
    mat-icon {
      font-size: 18px;
      vertical-align: text-top;
      margin-right: 4px;
    }
  }
  .commentUser {
    font-weight: bold;
  }
  .commentDate {
    text-align: right;
    color: var(--space-selectedColor);
    padding-right: 2px;
    .commentLabel {
      display: none;
    }
  }
  .spcCommentListCeldInfo {
    margin-bottom: 8px;
  }
  .spcCommentListCeldReadMore {
    padding-top: 4px;
    cursor: pointer;
  }
  .spcCommentListCeldText {
    line-height: 20px;
    max-height: 20px * 2;
    overflow: hidden;
    &._opened {
      max-height: initial;
      ::before {
        display: none;
      }
    }
  }
}

// constraints
.constraint-dialog .mat-dialog-container {
  width: calc(100vw - 100px) !important;
  max-width: 640px !important;
}
.constraintsList {
  margin-top: 12px;
}
.constraintWarnings {
  color: var(--space-selectedColorWarning) !important;
}
.constraintWarningsNum {
  color: var(--space-selectedColorWarning) !important;
}
.constraintsListCeld {
  &:not(:last-child) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px #ccc;
  }
  line-height: 1.3;
  padding-top: 6px;
  &.activeConstraint {
    .constraintsTitle {
      color: var(--space-selectedColor);
    }
  }
  .icon-constraintsList-wrapp {
    margin-left: -8px;
    opacity: 0.6;
  }
  .icon-constraintsList {
    font-size: 20px;
  }
  .constraintsList-wrapp-left {
    width: auto;
    float: left;
    margin-top: 4px;
  }
  .constraintsList-wrapp-right {
    width: auto;
    float: right;
    margin-top: 4px + 10px;
    margin-right: 6px;
    zoom: 0.9;
  }
  .constraintsList-wrapp-left-right {
    width: 100%;
    height: 42px;
    clear: both;
  }
}

#spcg_rightPanelContent {
  user-select: text !important;
}

/******/
/* TEMPLATES */
/******/
#spcg_templates #spcg_grid,
#spcg_floorplans #spcg_grid {
  --spcg-rigth-panel: 220px;
}

#spcg_templates #spcg_rightPanel {
  width: calc(var(--spcg-rigth-panel) * 1.6) !important;
}

#spcg_templates #spcg_top_tabs .spcg_top_tab {
  @media (min-width: $minScreen) {
    padding: 0 calc(28px + 10px);
    padding-left: calc(26px + 10px);
  }
}

body.theme-dark .psvg-stage-floorPlan rect {
  fill: #414c60;
}

.templates-etitor-form-tab {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.templates-etitor-form-tab-content {
  padding: 32px;
  width: 100%;
  /* height: 2000px; */
}

//MODO PREVIEW
#app-svg-canvas-previewMode #psvg-control-print {
  display: none !important;
}

.templ-block-child {
  --test: 1;
}

.levelSelector {
  display: inline-block;
  padding: 0 6px;
  background: #f8f9fc;
  border: solid 1px var(--space-border-color2);
  margin-left: 6px;
  opacity: 0.5;
  cursor: pointer;
  border-radius: 2px;

  body.theme-dark & {
    background: rgba(0, 0, 0, 0.2);
  }

  &:hover,
  body.theme-dark &:hover {
    opacity: 1;
    color: var(--main-greenColor);
    border: solid 1px var(--main-greenColor);
  }

  &._active,
  body.theme-dark &._active {
    opacity: 1;
    color: var(--card-color);
    background: var(--main-greenColor);
  }
}

[app-svg-block-info] {
  pointer-events: none;
  opacity: 0.7;
}

.matMenuFilters__,
.matMenuFiltersButton {
  font-size: 13px !important;
}

/******/
/* FLOOR-PLANS */
/******/
#spcg_floorplans #spcg_grid {
  --test: 1;
}

#psvg-canvas.psvg-canvas-floorplans .__fpModSelected {
  //MODULO SELECCIONADO
  opacity: 0 !important;
}

#psvg-canvas.psvg-canvas-floorplans.psvg-svgBase-inSelect .__fpModNotSelected .psvg-baseRect-floorPlan-module-child-fill {
  //MODULO NO SELECCIONADO
  opacity: 0.125 !important;
}

#psvg-canvas.psvg-canvas-floorplans [app-svg-floorplan-img] image {
  opacity: 1 !important;
}

#psvg-canvas.psvg-canvas-floorplans [app-svg-floorplan-img] .psvg-baseRect-drop {
  fill: #f0f0f0;
}

.psvg-fp-module-guide {
  fill: transparent;
  pointer-events: none;
  stroke: var(--space-selectedColor);
  stroke-width: 2px;
  z-index: 100000;
  &.psvg-fp-module-guide-warning {
    stroke: var(--space-selectedColorWarning);
  }
}

#svgRoot {
  opacity: 0.75;
  zoom: var(--space-readonly-scale);
}

.textFlipY {
  transform: scaleY(-1) scaleX(-1);
  transform-origin: center;
}

.atrrCell-values {
  margin: 0 !important;
  padding-top: 6px !important;
  padding-bottom: 12px !important;
  border-bottom: 1px solid var(--spcg-border-color2) !important;

  .barChart {
    height: 6px;
    padding-bottom: 2px !important;
  }

  .subTit {
    margin-top: 6px !important;
    padding-bottom: 3px !important;
    font-size: 12px !important;
  }

  .panelAlert {
    margin-top: 12px !important;
    padding: 2px !important;
    line-height: 24px !important;
  }
}
.atrrCell-label b {
  display: inline-block !important;
  width: calc(100% - 30px) !important;
  vertical-align: middle !important;
}

body.theme-dark {
  --space-border-color0: #505a6b;
  --space-border-color1: #505a6b;
  --space-border-color2: #505a6b;
  --space-border-color3: #52627a;

  #spcg_grid {
    --spcg-border-color1: #505a6b;
    --spcg-border-color2: #505a6b;
    --spcg-content-bgcolor: #262b35;
  }

  #psvg-stage {
    rect {
      fill: #66738a;
    }
  }

  #spcg_fullMenuBar {
    background-color: #171c23;
    border-bottom: 1px solid #69748480;
  }

  #auditViewImgWrapp {
    background-color: transparent;
  }
}

.spcg_panelContentBox .formFieldIcon {
  padding-top: 0px !important;
}

// CAT-386 (tabs readonly globalmente)
.globalReadOnly #spcg_bottom_row,
.globalReadOnly .spaceCellProd,
.globalReadOnly .spaceCellPop {
  pointer-events: none;
  opacity: 0.7;
}

.globalReadOnly #spcg_bottom_row #generalTabWrapp > *,
.globalReadOnly .spaceCellProd #generalTabWrapp > *,
.globalReadOnly .spaceCellPop #generalTabWrapp > * {
  pointer-events: none;
  opacity: 0.7;
}

.globalReadOnly #canvasViewMenuBar {
  pointer-events: all !important;
}
