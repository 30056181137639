/**
 * Contiene las constantes que vamos a usar en los scss
 */
$highlight-color: var(--highlight-color);
$selected-color: #a3f59d61;
$primary-color: var(--main-greenColor);
$negative-color: var(--main-redColor);
$positive-color: var(--main-greenColor);
$default-icon-color: var(--default-icon-color);
$header-background-color: #eeeff2;
$negative-color-map: #ca3d3d;
$positive-color-map: #2aad77;
$neutral-color-map: #808080;

/*
* HELPERS
*/
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.animation-toolbar {
  transition: all 0.3s;
}

.background-fullsecreen {
  background-color: #f8f9fc;
}
